.container {
    padding: 0rem 2rem;
    display: flex;
    justify-content: center;
}

.wrapper {
    display: flex;
    align-items: baseline;
    justify-content: center;
    position: relative;
    padding: 0 5rem;
}

.wrapper>img {
    width: 30rem;
    position: relative;
    bottom: 0;
}

.blueCircle {
    width: 30rem;
    height: 30rem;
    border-radius: 50%;
    z-index: -99;
    position: absolute;
    bottom: 0; 
    background: linear-gradient(135.74deg, #0bb5ff 14.09%, #6dd3fe 83.81%)
}

.cart {
    position: absolute;
    right: -8%;
    bottom: 20%;
    display: flex;
    gap: 1rem;
    align-items: center;
}

.cart>svg {
    /* font-size: 2rem; */
    width: 30px;
    height: 30px;
    background-color: #fff;
    padding: 10px;
    border-radius: 50%;
    border: 6px solid var(--black);
}

.signup {
    background: #fff;
    width: max-content;
    height: min-content;
    padding: 10px;
    border-radius: 15px;
    display: flex;
    gap: 1rem;
    font-size: 0.8rem;
    box-shadow: var(--shadow1);
    align-items: center;
}

.signup>:first-child {
    display: block;
    width: 6rem;
}

.signup>:nth-child(2) {
    border-radius: 50%;
    border: 1px solid skyblue;
    display: flex;
    width: 20px;
    height: 20px;
    padding: 5px;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width:856px) {
    .cart {
        right: -8%;
        width: 40%;
    }
}

@media screen and (max-width:640px) {
    .blueCircle {
        width: 20rem;
        height: 20rem;
    }

    .wrapper>img {
        width: 20rem;
    }

    .cart {
        width: 75%;
        bottom: -5%;
        left: 50%;
        right: 50%;
        transform: translate(-50%, -50%);
        scale: 0.8;
    }
}