.container {
    padding: 1.5rem;
    display: flex;
}

.logo {
    display: flex;
    align-items: center;
    flex: 1;
    gap: 0.8rem;
    cursor: pointer;
}

.logo>img {
    width: 2.5rem;
    height: 2.5rem;
}

.logo>span {
    font-weight: 600;
    font-size: 1.5rem;
}

.right {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.menu {
    display: flex;
    gap: 1rem;
    list-style: none;
    cursor: pointer;
}

.menu>li:hover {
    color: #fff;
    transition: 0.3s ease-in-out;
}

.search {
    outline: none;
    border: none;
    padding: 0.5rem;
    border-radius: 5px;
    max-width: 7rem;
    height: 30%;
}

.cart {
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
    transition: 0.3s all ease-in-out;
}

.cart:hover {
    color: #fff;
}

.bar {
    display: none;  
}

@media screen and (max-width:856px) {
    .search {
        display: none;
    }
    .right {
        gap: 1rem;
    }
}

@media screen and (max-width:640px) {
    .right {
        position: absolute;
        right: 2rem;
        z-index: 9999;
        background: #fff;
        color: var(--black);
        border-radius: 5px;
        flex-direction: column;
        padding: 1rem;
    }

    .menu {
        flex-direction: column;
        margin-left: -2rem;
        display: none;
    }

    .bar {
        display: block;
    }
    
    .search {
        display: none;
    }
}