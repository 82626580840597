.container {
    padding: 2rem;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    justify-content: center;
}

.container>:first-child {
    position: absolute;
    width: 8rem;
    left: 30%;
    top: -3rem;
}

.products {
    display: grid;
    width: 90%;
    grid-template-columns: 25% auto;
}

.menu {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    font-weight: 500;
    font-size: 1.3rem;
}

.menu>li:hover {
    color: var(--pink);
    cursor: pointer;
    transition: 0.3s all ease-in-out;
}

.list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    height: 25rem;
    overflow-y: scroll;
    grid-gap: 2rem;
    justify-content: space-between;
}

.product {
    width: 12rem;
    height: 8rem;
    background-color: #fff;
    position: relative;
    overflow: hidden;
    padding: 1rem;
    display: flex;
    border-radius: 1rem;
    cursor: pointer;
}

.product>:hover {
    opacity: 0.7;
    transition: 0.3s all ease-in-out;
}

.product>img {
    top: 3rem;
    width: 6rem;
    height: 11rem;
}

@media screen and (max-width:856px) {
    .container {
        gap: 0;
    }

    .products {
        grid-template-columns: auto;
    }

    .menu {
        flex-direction: row;
    }

    .product {
        width: 15rem;
    }
}

@media screen and (max-width:640px) {
    .container {
        align-items: flex-start;
    }

    .container>h1 {
        font-size: 1.8rem;
        text-align: center;
    }

    .menu {
        overflow-x: scroll;
    }

    .product {
        /* width: 20rem; */
    }

    .list {
        grid-template-columns: 100%;
    }
    
}